.rdt_TableHeadRow {
    font-family: var(--primary-font-bold);
    font-size: 12px !important;
    line-height: 14px !important;
    /* color: var(--primary-color); */
    color: white;
    text-transform: uppercase;
    background: #3c3c43 !important;
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.25) !important;
    border-radius: 5px;
    max-width: 100% !important;
    width: 98%;
    min-height: 7vh !important;
    height: 7vh !important;
    max-height: 30vh;
    flex: 1;
    justify-content: space-between;
    word-wrap: break-word;
    /* white-space: unset; */
    z-index: 2;
}
.fbRFLN{
    justify-content: right !important;
}
.rdt_TableHead {
    z-index: 1 !important;
}

.rdt_TableCol,
.rdt_TableCol_Sortable {
    height: 7vh;
    word-wrap: break-word;
    white-space: unset;
    max-height: 10vw;
}

.rdt_TableRow {
    font-family: var(--primary-font-medium);
    font-size: 10px;
    line-height: 14px;
    color: rgb(76, 73, 73) !important;
    text-transform: none;
    /* max-width: fit-content !important; */
    flex: 1;
    justify-content: space-between;
    /* cursor: pointer !important; */
}


.rdt_Table {
    max-width: 98% !important;
    width: 95%;
    margin: 0vh 1vw 1vh 1vw;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    appearance: none !important;
    outline: none !important;
    flex-grow: inherit;
    white-space: unset;
    /* height: 100vh !important; */
    /* display: flex; */
    /* align-items: justify; */
  /* flex-wrap: nowrap; */
  /* align-items: stretch; */
}

/* .rdt_TableCol:last-child{
    max-width: 100% !important;
    margin-right: 1vw;
    text-align: left;
} */

.rdt_TableCell {
    /* min-width: 180px  !important; */
    width: 300px;
    /* max-width: max-content; */
    /* background: yellowgreen; */
    white-space: unset;
}

.jxflYm {
    font-family: var(--primary-font-medium) !important;
}

.rdt_Pagination {
    min-height: 6vh !important;
    height: 6vh !important;
}

.eIoOYs {
    max-height: 60vh !important;
    /* width: 98% !important; */
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -ms-appearance: none !important;
    appearance: none !important;
    outline: none !important;
}

.rdt_TableCol {
    width: 100% !important;
    max-width: 100% !important;
  }

.rdt_TableCol_Sortable {
  width: 100% !important;
  max-width: 100% !important;
}

.rdt_TableCell {
  max-width: 100% !important;
  width: 100% !important;
}