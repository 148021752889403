.login-cont {
    border: 3px solid var(--primary-color);
    box-sizing: border-box;
    border-radius: 45px;
    padding: 3% 15% 5% 15%;
}

.login-logo {
    width: 100%;
    padding: 10%;
    margin-bottom: 5%;
}

.mt-10 {
    margin-top: 10%;
}

.username-input,
.password-input {
    font-family: var(--primary-font-medium);
    font-weight: bold;
    margin-bottom: 1%;
    width: 100%;
    padding: 3% !important;

    border: none !important;
    background: #E8E8E8 !important;
    border-top-right-radius: 9px !important;
    border-bottom-right-radius: 9px !important;
}

.form-control:focus,
.form-select:focus,
.react-select__control--is-focused {
    border-color: var(--primary-color) !important;
    box-shadow: 0 0 0 0.1rem var(--primary-color) !important;
}

.icon-text {
    background: #E8E8E8 !important;
    z-index: 1 !important;
    height: 93%;
    border-top-left-radius: 9px !important;
    border-bottom-left-radius: 9px !important;
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
}

.icon-text-2 {
    background: #E8E8E8 !important;
    z-index: 1 !important;
    height: 90%;
    border-top-left-radius: 9px !important;
    border-bottom-left-radius: 9px !important;
    border-left: none !important;
    border-top: none !important;
    border-bottom: none !important;
}

.icon-cont {
    z-index: 1;
    width: 15%;
}

.login-btn {
    width: 100%;
    padding: 3%;
    margin-top: 5%;

    border: none;
    background: var(--primary-color);
    border-radius: 9px;
    color: #ffffff;
    letter-spacing: 0.1em;
    font-weight: bold;
    font-family: var(--primary-font-medium);
}

.login-btn:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
}

.username-icon {
    width: 130%;
    margin-left: 5%;
    color: #0077b6;
}

.grand-label{
    font-size: 11pt !important;
    margin-left: -1%;
}

.grand-label-review {
    margin-left: -10%;
    margin-top: 2% !important;
    color:var(--primary-color) !important;
    font-weight: bold;
    font-family: var(--primary-font-medium);
}

.eye-icon-login {
    display: inline !important;
    margin-left: -13% !important;
    margin-top: 2.5% !important;
    z-index: 10;
    color: #282c34;
    cursor: pointer;
}

.eye-icon-login-2 {
    display: inline !important;
    margin-left: -10% !important;
    margin-top: 2.2% !important;
    z-index: 10;
    color: #282c34;
    cursor: pointer;
    font-size: 24px;
}

.reset-label {
    font-family: var(--primary-font-medium);
    font-size: 14px;
    text-align: left;
}

.custom-border-radius {
    border-top-right-radius: 10 ! important;
    border-bottom-right-radius: 10 ! important;
}

/* navigation tabs */
.nav-link,
.nav-tabs .nav-link:hover {
    font-family: var(--primary-font-medium);
    font-size: 16px !important;
    line-height: 19px !important;
    color: var(--primary-color) !important;
    text-transform: uppercase !important;
    text-align: center;

    background: #FFFFFF;
    border: 1px solid var(--primary-color) !important;
    border-radius: 10px 10px 0px 0px !important;
    padding: 1vh .5vw !important;
    width: 10vw;
}

.nav-link.active,
.nav-link.active:hover,
.react-select__option--is-selected {
    color: white !important;
    background: var(--primary-color) !important;
}

.react-select__option:hover,
.react-select__option--is-active {
    background: #cdffd2;
}

.tab-content {
    font-family: var(--primary-font-medium);
    font-size: 15px;
    line-height: 18px;
    color: #5E5E5E;
    text-transform: uppercase;

    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 5px 5px 5px 5px;
    /* box-shadow: 0px -3px 10px rgba(0, 0, 0, 0.2); */
    /* height: 80vh !important;
    max-height: 80vh !important; */
}

/* date filters */
.PO-filters .form-control,
.PO-filters .form-select,
.add-select,
.PO-select-action.form-select {
    background-color: var(--primary-color) !important;
    border-radius: 7px !important;
    color: white !important;
    max-height: 35px !important;
}

.PO-select-action.form-select,
.PO-filters .form-select,
.add-select {
    background-image: url(/src/Assets/Images/caret-down.png) !important;
}

.PO-select-action.form-select {
    background-size: 10% !important;
}

.PO-filters .form-select {
    background-size: 7% !important;
}

.add-select {
    background-size: 5% !important;
    width: 30% !important;
    margin-left: 3%;
}

.PO-select-action.form-select,
.PO-select-action.form-select:focus {
    font-family: var(--primary-font-regular);
    font-size: 13px;
    line-height: 16px;
    color: white;

    box-shadow: none;
    border: none;
    width: 6vw;
    padding: 1vh .5vw;
}

.PO-filters .form-select,
.add-select,
.PO-filters .form-select:focus {
    font-family: var(--primary-font-regular);
    line-height: 16px;
    color: white !important;

    box-shadow: none !important;
    border: none !important;
    padding: 1vh .5vw !important;
}

.PO-filters .form-select option,
.add-select option {
    background: white;
    color: #5E5E5E;
}
.adjust-add{
    width: 25vw;
}
.PO-filters .form-control,
.PO-filters .form-select {
    max-width: 14w;
}

.PO-filters .ant-picker-outlined {
    background-color: var(--primary-color) !important;
    font-family: var(--primary-font-medium);
}

.PO-filters .ant-picker .ant-picker-input {

    .ant-picker-suffix, input::placeholder, input {
        color: white !important;
    }
    
}

/* edit and add css */
.align-right {
    justify-content: end;
}

.edit-optional {
    font-family: var(--primary-font-light);
    font-size: 15px;
    line-height: 18px;
    color: #B9B9B9;
}

.underlined {
    text-decoration: underline !important;
}

.form-table td,
.form-table th {
    color: #5E5E5E;
    font-weight: bold;
    vertical-align: middle;
}

.view-table th {
    color: #5E5E5E;
    font-weight: bold;
    text-transform: none !important;
    vertical-align: middle;
    text-transform: uppercase !important;
}

.view-table,
.form-control {
    width: 10vw;
}

.form-table {
    width: 80vw;
}

.form-table,
.view-table {
    padding: 1vh 1vw;
}

.PO-add-item {
    justify-content: flex-start;
}

.PO-add-item button,
.add-item {
    font-family: var(--primary-font-medium);
    font-size: 13px;
    line-height: 16px;

    background: var(--primary-color);
    border-radius: 7px;
    border: none;
    padding: 1.5vh 2vw;
    margin-left: 1vw;
    width: auto !important;
}

.PO-add-item button:hover,
.PO-add-item button:active,
.PO-add-item button:focus,
.add-item:hover,
.add-item:active,
.add-item:focus {
    background: var(--primary-color);
    box-shadow: none !important;
}

.edit-cancel button,
.edit-save button,
.print-print,
.print-cancel {
    font-family: var(--primary-font-light);
    font-size: 15px;
    line-height: 18px;
    text-transform: capitalize;
    letter-spacing: 0.06em;
    color: white;
}

.edit-cancel button {
    background: var(--primary-color);
    border-radius: 7px;
    border: none;
}

.edit-cancel button:hover,
.edit-cancel button:focus,
.edit-cancel button:active {
    background: var(--primary-color);
    box-shadow: none !important;
}

.edit-save button,
.print-print {
    background: #0077b6;
    border-radius: 7px;
    border: none;
}

.edit-save button:hover,
.edit-save button:focus,
.edit-save button:active,
.print-print:hover,
.print-print:focus,
.print-print:active {
    background: #f1b900;
    box-shadow: none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.align-contents {
    justify-content: space-around;
}

.row {
    align-items: center;
}

/* review css */
.review-label {
    color: #5E5E5E !important;
}

.review-data {
    text-transform: capitalize !important;
}

.print-table th {
    font-size: 10pt !important;
    text-transform: uppercase;
    font-family: var(--primary-font-medium) !important;
}

.review-purchased-items td,
.print-table td {
    text-transform: capitalize;
    color: #5E5E5E;
    font-family: var(--primary-font-medium) !important;
    font-size: 10pt !important;
}

.review-purchased-items td:nth-child(4),
.review-purchased-items td:nth-child(5),
.print-table td:nth-child(4),
.print-table td:nth-child(5),
.print-table-footer-data {
    color: var(--primary-color);
    font-family: var(--primary-font-regular) !important;
}

.print-grand-total {
    justify-content: flex-end;
    margin: 1vh 9% !important;
}

/* .review-purchased-items {
    width: 65vw !important;
} */

.review-container {
    background: #E6E5E5;
    border-radius: 5px;

    margin: 2vh 0;
}

.review-po {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-radius: 20px;

    padding: 1vh 1vw;
    height: max-content;
    color: white !important;
}

/* return modal css */
.return-header {
    font-family: var(--primary-font-medium);
    font-size: xx-large;
    line-height: 43px;
    color: #0077b6;

    text-transform: uppercase;
    justify-content: center !important;
}

.return-body {
    font-family: var(--primary-font-medium);
    font-size: 20px;
    line-height: 24px;
    color: #545454;
}

.return-footer {
    border-top: none !important;
}

/* print PO css */
.print-PO {
    height: 50vh !important;
    overflow-y: scroll !important;
}

.print-header span,
.review-po {
    font-family: var(--primary-font-medium);
    font-size: 15px;
    line-height: 18px;
    color: #545454;
}

.print-logo {
    width: 180px;
}

.print-label,
.print-signatories span {
    font-family: var(--primary-font-light);
    font-size: 15px;
    line-height: 18px;
    color: #545454;
    text-transform: capitalize;
}

.print-data {
    font-family: var(--primary-font-medium);
    font-size: 15px;
    line-height: 15px;
    color: #545454;
    text-transform: capitalize;
}

.print-footer {
    font-style: italic;
    font-size: 14px;
    color: #545454;
    font-family: var(--primary-font-medium);
}

.print-table {
    width: 90vw !important;
    padding: 1vh 1vw;
    font-family: var(--primary-font-regular) !important;
}

.print-table-footer {
    display: flex;
    justify-content: end;
    margin: 1vh 6rem 1vh 0;
}

.print-table-footer-label, .print-table-footer-data {
    font-size: 10pt !important;
    font-family: var(--primary-font-medium) !important;
}


.print-close {
    line-height: 18px;
    background: #FFFFFF;
    border: 1px solid var(--primary-color);
    border-radius: 7px;
    color: var(--primary-color) !important;
    height: 5vh;
}

.print-close:hover {
    color: white !important;
    background-color: var(--primary-color);
}

.print-print {
    height: 5vh;
}

.print-buttons {
    background-color: #F8F7F7;
    height: 10vh;
    position: absolute;
    bottom: 0;
    left: 10vw;
    width: 85vw;

    align-items: center;
}

.print-signatures,
.print-signatories {
    display: flex;
    justify-content: space-around;
}

.print-signatures {
    margin-top: 10vh;
}

.print-signatures span {
    border-bottom: 1px solid #E5E5E5;
    /* width: 30vw; */
    padding: 0 10px;
}

.color-yellow {
    color: #0077b6 !important;
    background: white;
}

.color-red {
    color: #DC3545 !important;
    background: white;
}
