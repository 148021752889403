.app-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .custom-td {
    border: 1px solid #000000 !important;
    text-align: left;
    border: collapse;
    padding: 0.5%;
  }

  .custom-th {
    background-color: #73cbfb;
  }

  
  form {
    display: flex;
    gap: 5px;
  }
  
  form td:last-child {
    display: flex;
    justify-content: space-evenly;
  }
  
  form * {
    font-size: 28px;
  }

  .custom-row {
    background-color: #f2f8ff;
  }

  .print-table-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  .react-datepicker__aria-live {
    display: none !important;
  }

  .underline-col {
    display: flex; 
    /* justify-content: space-between; */
    /* align-items: center; */
    
  }
  
  .underline-col::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #000;
    margin-left: 8px; 
    margin-right: 8px;
  }

  .ant-picker-suffix .anticon {
    width: 50%;
  }

  .bottom-zero {
    position: relative !important;
    bottom: 0 !important;
    background-color: gold;
    padding: 0 !important;
    height: 100% !important;
    width: auto;
  }

  .print-text {
    font-size: 10px !important;
    font-family: var(--primary-font-medium);
  }

  .smaller-print-text {
    font-size: 8px !important;
    font-family: var(--primary-font-medium);
  }

  .print-pdf-header {
    font-size: 14px !important;
    font-family: var(--primary-font-medium);
  }

  .print-pdf-container {
    /* width: 790px !important; */
    height: 1120px !important;

    .ant-row, .ant-col {
      padding: 0 !important;
    }
  }

  .w-90 {
    width: 90% !important;
    height: auto !important;
  }

.print-pdf-img-container {
  border: 2px solid red;
}