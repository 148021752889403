/* modal */
.modal-footer {
  border-top: none !important;
}

.font-primary{
  font-family: var(--primary-font-medium);
  color: rgb(81, 81, 81) !important;
}

.landing-page-container{
  background-color: var(--secondary-color);  
  height: 100vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  img{
    width: 40%;
  }
}

.hidden{
  display: none;
}

.card-container{
  background-color: white;
  border-radius: 1rem;
  height: 100%;
  cursor: pointer;
}

.logo-container{
  background-color: white;
  border-radius: 100%;
  height: 9rem;
  width: 9rem;
  padding: .5rem;
  overflow: hidden;

  img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.logo-container-inactive {
  background-color: white;
  border-radius: 100%;
  width: 3.5rem;
  height: 3.5rem;
  /* padding: .5rem; */
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.w-full{
  width: 100%;
}

/* buttons */
.button-secondary,
.button-primary,
.button-tertiary,
.button-warning,
.dropdown-toggle btn btn-primary,
.button-warning-fill {
  font-family: var(--primary-font-medium);
  font-size: 12pt;
  line-height: 18px;

  text-transform: capitalize;
  letter-spacing: 0.06em;

  border-radius: 7px;
  box-shadow: none;
  width: 9vw;
  min-height: 4vh;
}

.button-warning {
  font-family: var(--primary-font-medium);
  background-color: red;
  font-size: 12pt;
  line-height: 18px;

  text-transform: capitalize;
  letter-spacing: 0.06em;

  border-radius: 7px;
  box-shadow: none;
  width: 9vw;
  min-height: 4vh;
}

.button-fourth {
  background-color: var(--primary-color);
  color: white;
  width: 100%;
  border-radius: 10px;
  font-family: var(--primary-font-medium);
  border: none;
  padding: 0.5rem 1rem;
  font-size: 12pt;
}

.button-default {
  background-color: white;
  color: gray;
  border: 1px solid gray;
  /* font-family: var(--primary-font-medium);
    border-radius: 0.5em;
    width: 9vw;
    font-size: 12pt; */

  font-family: var(--primary-font-medium);
  font-size: 12pt;
  line-height: 18px;
  text-transform: capitalize;
  letter-spacing: 0.06em;
  border-radius: 7px;
  box-shadow: none;
  width: 9vw;
  min-height: 4vh;
}

.dropdown-toggle btn btn-primary {
  width: "100%" !important;
}

.dropdown-item {
  font-family: var(--primary-font-medium);
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  letter-spacing: 0.06em;
}

.button-primary:hover,
.button-primary:focus,
.button-primary:active,
.button-secondary:hover,
.button-secondary:focus,
.button-secondary:active,
.button-tertiary:hover,
.button-tertiary:focus,
.button-tertiary:active,
.button-warning:hover,
.button-warning:focus,
.button-warning-fill:active,
.button-warning-fill:hover,
.button-warning-fill:focus,
.button-warning-fill:active,
.dropdown-toggle btn btn-primary {
  box-shadow: none;
}

.button-primary,
.dropdown-toggle btn btn-primary {
  background: var(--primary-color) !important;
  border: none !important;
  color: white;
  text-decoration: none;
}

.button-primary:disabled, 
.button-secondary:disabled, 
.button-warning-fill:disabled {
  background: rgb(212, 209, 209) !important;
  border: rgb(212, 209, 209) !important;
  color: white;
  cursor: not-allowed;
}

.button-primary:hover,
.dropdown-toggle btn btn-primary {
  background: var(--primary-color);
  border: none;
  color: white;
}

.dropdown-toggle btn btn-primary {
  border-radius: 0 !important;
}

.button-primary-clear {
  background: white;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}

.button-primary-clear:hover {
  background: var(--primary-color);
  border: none;
  color: white;
}

.button-secondary {
  background: white;
  border: 1px solid #0077b6;
  color: #0077b6;
}

/* .button-secondary:hover {
    background: #0077b6;
    border: 1px solid #0077b6;
    color: white;
} */

.button-tertiary {
  background: #0077b6;
  border: 1px solid #0077b6;
  color: white;
}

.button-tertiary:hover {
  background: #0077b6;
  border: 1px solid #0077b6;
}

.button-warning {
  background: white;
  border: 1px solid #dc3545;
  color: #dc3545;
}

.button-warning:disabled {
  background: rgb(212, 209, 209) !important;
  cursor: not-allowed;
  border: rgb(212, 209, 209) !important;
  color: white;
}

.button-warning:hover {
  border: 2px solid #dc3545;
  font-weight: 600;
}

.button-warning-fill {
  background: #dc3545;
  border: 1px solid #dc3545;
  color: white;
}

.button-warning-fill:hover {
  background: #df1227;
  border: 1px solid #df1227;
}

.w-90 {
  width: 95% !important;
}

.react-select__menu,
.react-select__value-container {
  font-size: 1vw !important;
}

.ant-select {
  font-family: var(--primary-font-medium) !important;
}

.ant-upload-text,
.ant-upload-list-item-name {
  font-family: var(--primary-font-medium);
  color: gray !important;
  font-size: 9pt !important;
}

.ant-upload-drag-container img {
  width: 3% !important;
}

.ant-modal-title {
  font-family: var(--primary-font-medium);
  color: var(--primary-color) !important;
  font-size: 14pt !important;
  text-transform: uppercase !important;
}

.review-form-label {
  font-family: var(--primary-font-medium) !important;
  color: gray !important;
}

.review-form-input {
  font-family: var(--primary-font-medium) !important;
  color: gray !important;
  font-size: 10pt !important;
}

.ant-modal-confirm-title {
  font-family: var(--primary-font-medium) !important;
  color: rgb(65, 65, 65) !important;
}

.ant-modal-confirm-content {
  font-family: var(--primary-font-regular);
}

.ant-btn-default {
  font-family: var(--primary-font-regular);
}

.ant-btn.button-primary {
  font-family: var(--primary-font-medium);
}

.ant-btn.button-primary:hover {
  color: white !important;
}

.image-preview-container {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 100%;
}

.image-preview-placeholder {
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 100%;
  background-color: rgb(229, 229, 229);
}


.ant-radio-wrapper {
  font-family: var(--primary-font-medium);
  font-size: 12pt;
}

.ant-table {
  .ant-table-thead {
      .ant-table-cell {
          background-color: var(--primary-color);
          color: white !important;
          font-family: var(--primary-font-medium);
          padding: 1%;
      }
  }

  .ant-table-tbody {
    .ant-table-cell {
      font-family: var(--primary-font-regular);
      padding: 1%;

      /* .ant-table-row-expand-icon {
        color: white;
        background-color: var(--primary-color);
        transform: scale(1.25);
      } */
    }

    .ant-table-row-level-0 {
      .ant-table-cell {
        font-family: var(--primary-font-regular) !important;

        .ant-table-row-expand-icon {
          color: white;
          background-color: var(--primary-color);
          transform: scale(1.25);
        }
      }
    }

    .ant-table-row-level-1 {
      .ant-table-cell {
        .ant-table-row-expand-icon {
          background-color: white;
          color: var(--primary-color);
          transform: scale(1.25);
        }
      }
    }
  }
}

.text-white{
  color: white !important;
}

.css-1550uzu-placeholder {
  grid-area: 1 / 1 / 2 / 3;
  color: white;
  margin-left: 2px;
  margin-right: 2px;
  box-sizing: border-box;
  font-size: 13px;
}

.css-1ijvnz7-singleValue {
    grid-area: 1 / 1 / 2 / 3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: white;
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
    font-family: var(--primary-font-medium);
    font-size: 13px;
}

.font-primary{
  font-family: var(--primary-font-medium) !important;
}

.income-statement{
  overflow: scroll;
  .ant-table {
      & .ant-table-tbody {
        .ant-table-cell {
          font-family: var(--primary-font-regular);
          padding: 1%;
          width: 10px;
        }
      }
    }
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-outlined:hover {
  border-color: var(--secondary-color);
  background-color: #ffffff;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-outlined:focus,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-outlined:focus-within {
  border-color: var(--secondary-color);
  box-shadow: 0 0 0 2px rgba(198, 198, 198, 0.1);
  outline: 0;
  background-color: #ffffff;
}


._inactive{
  position: absolute;
  height: 85%;
  width: 90.5%;
  border-radius: 16px;
  background-color: rgb(255, 255, 255);
  opacity: 50%;
  cursor:not-allowed;
}

/* Chart of accounts nested list */
.subtypes-column ul {
  list-style-type: none;
  padding-left: 1rem;
}

.subtypes-column ul ul {
  padding-left: 1rem;
}

.subtypes-column li::before {
  content: "• ";
}
/* Chart of accounts nested list */

.ant-picker-cell .ant-picker-cell-in-range .ant-picker-cell-in-view::before{
  background-color: #0077b6 !important;
}

.custom-anchor{
  text-decoration: none;
  color: white;
}

.bu-units-container{
  margin-top: 6rem;
}

.landing-page-header{
  padding-top: 1rem;
  padding-bottom: 1.3rem;
  background-color: #424242;
}

.table-container{
  overflow: hidden;
  border-radius: 1rem;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-spin .ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color:var(--primary-color);
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation-name: css-dev-only-do-not-override-1rqnfsa-antSpinMove;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-direction: alternate;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-disabled) .ant-picker-cell-inner,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-disabled) .ant-picker-cell-inner {
  color: #fff;
  background: var(--primary-color);
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  z-index: 1;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  content: "";
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-outlined:hover {
  border-color: var(--primary-color);
  background-color: #ffffff;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  background: var(--primary-color);
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}

@media (max-width: 578px) {
  .landing-page-logo {
    img {
      width: 70%;
    }
  }
}

@media (min-width: 580px) {
      .landing-page-logo {
          img{
            width: 20%;
          }
        }
}