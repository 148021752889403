/* .validity-error {
	text-transform: capitalize;
	font-size: 11px;
	margin-bottom: 0 !important;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: lighter !important;
	text-transform:lowercase;
}

.validity-error::first-letter {
	text-transform: capitalize !important;
}

div.callout {
	font-size: x-small;
	background-color: #8be6f0;
	background-image: -moz-linear-gradient(top,#8be6f0, #8be6f0);
	position: relative;
	color: #212529;
	width: fit-content;
	padding: 2px 8px;
	border-radius: 3px;
	border: 1px solid #8be6f0;
	margin-top: 0.2em;
}

.callout::before {
	content: "";
	width: 0px;
	height: 0px;
	border: 0.8em solid transparent;
	position: absolute;
}

.callout.bottom::before {
	left: 45%;
	top: -19px;
	border-bottom: 10px solid #0077b6;
	z-index: 1000 !important;
} */

/* src/FloatingError.css */
.validity-error {
	text-transform: lowercase;
	font-size: 11px;
	margin-bottom: 0 !important;
	font-family: var(--primary-font-medium);
	font-weight: lighter !important;
	position: absolute; /* Ensure it floats over the input */
	background-color: red;
	color: white;
	padding: 2px 8px;
	border-radius: 3px;
	border: 1px solid red;
	z-index: 1000;
	width: fit-content;
	white-space: nowrap;
	margin-top: 2pt;
  }
  
  .validity-error::first-letter {
	text-transform: capitalize !important;
  }
  
  .callout::before {
	content: "";
	width: 0px;
	height: 0px;
	border: 0.8em solid transparent;
	position: absolute;
  }
  
  .callout.bottom::before {
	left: 45%;
	top: -19px;
	border-bottom: 10px solid red;
	z-index: 1000 !important;
  }
  
  .position-relative {
	position: relative;
  }
  