.modal-dialog {
    justify-content: center;
}

/* body.modal-open> :not(.modal) {
    filter: blur(5px);
} */

.modal-backdrop.show {
    opacity: .3 !important;
}

.modal-content {
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px !important;
    border: 1px solid white;
    display: flex;
    flex-direction: row;
}

.text-wrapper {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-content: center;
    /* width: fit-content; */
}

.text {
    display: block;
    margin: 0px !important;
}

.warning {
    width: 15%;
}

.modal-content h1 {
    font-family: var(--primary-font-medium);
    font-style: normal;
    font-weight: 900;
    font-size: xx-large;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #DC3545;
    justify-content: space-evenly;
}
.expense-modal-body-title {
    margin-top: 2%;
    text-align: left;
    font-family: var(--primary-font-medium);
    font-style: normal;
    font-weight: 900;
    font-size:larger;
    color: #545454;
    margin-left: 4%;
}
.expense-modal-body-title-right{
    margin-top: 2%;
    text-align: right;
    font-family: var(--primary-font-medium);
    font-style: normal;
    font-weight: 900;
    font-size:larger;
    color: #545454;
}
/* .custom-modal-body-title {
    margin-top: 0;
    text-align: left !important;
    font-family: var(--primary-font-medium);
    font-style: normal;
    font-weight: 900;
    font-size: xx-large;
    color: #545454 !important;
    margin-left: 2% !important;
} */

.modal-content h2 {
    width: max-content;
    font-family: var(--primary-font-medium);
    font-style: bold;
    font-weight: 900;
    font-size: 1.2rem;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: justify;
    color: #545454 !important;
    max-width: 100%;
    margin-top: 5%;
}

.modal-content h3 {
    width: max-content;
    font-family: var(--primary-font-medium);
    font-style: normal;
    font-weight: 100;
    font-size: 15px;
    display: flex;
    align-items: center;
    text-align: justify;
    color: #545454;
    max-width: 100%;
    margin: 0% !important;
}

.delete-btn {
    font-family: var(--primary-font-medium);
    font-weight: lighter;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.06em;
    border: 1px solid #DC3545;
    padding: .5vh 1.5vw !important;
    margin-left: 3%;
    background: #DC3545;
    border-radius: 7px;
    color: white;
}

.cancel-btn {
    font-family: var(--primary-font-medium);
    font-weight: lighter;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.06em;
    border: 1px solid #DC3545;
    padding: .5vh 1.5vw !important;
    margin-left: 3%;
    background: #FFFFFF;
    border-radius: 7px;
    color: #DC3545;
}

.cancel-btn-2 {
    font-family: var(--primary-font-medium);
    font-weight: lighter;
    font-size: medium;
    /* height: 60%; */
    width: 15vh;
    padding: 1%;
    margin-left: 3%;
    background: var(--primary-color);
    border: none !important;
    border-radius: 10px;
    color: #FFFFFF;
}

.save-btn {
    font-family: var(--primary-font-medium);
    font-weight: lighter;
    font-size: medium;
    /* height: 60%; */
    width: 15vh;
    border: none;
    padding: 1%;
    margin-left: 3%;
    background: #0077b6;
    border-radius: 10px;
    color: #ffff;
}

/* .logo {
    padding: 0% !important;
    margin: 0% !important;
    height: 250px;
} */

.nc-modal-custom-row {
    font-family: var(--primary-font-medium);
    color: var(--primary-color);
    width: 100%;
    margin-top: 2vh !important;
}



/* body.modal-open> :not(.modal) {
    filter: blur(5px);
} */

.modal-content {
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px !important;
    display: flex;
    flex-direction: row;
}

.text {
    display: block;
    margin: 0px !important;
}

.modal-content h1 {
    font-family: var(--primary-font-medium);
    font-style: normal;
    font-weight: 900;
    font-size: 3rem;
    line-height: 100%;
    display: flex;
    align-items: center;
    color: #DC3545;
    justify-content: space-evenly;
}

.custom-modal-body-title {
    text-align: left !important;
    font-family: var(--primary-font-medium);
    font-style: normal;
    font-weight: 900;
    font-size: x-large;
    color: #545454 !important;
    margin-left: 0.6rem !important;
    margin-bottom: 0;
}

.custom-modal-body-title-user-details {
    margin-top: 10%;
    padding-left: 4% !important;
    text-align: left !important;
    font-family: var(--primary-font-medium);
    font-style: normal;
    font-weight: 900;
    font-size: xx-large;
    color: #545454 !important;
    background-image: url("../../Assets/Images/Modal/userdeets.png");
    background-repeat: no-repeat;
    background-position: 1% 50%;
}

.modal-content h3 {
    width: max-content;
    font-family: var(--primary-font-medium);
    font-style: normal;
    font-weight: 100;
    font-size: 15px;
    display: flex;
    align-items: center;
    text-align: justify;
    color: #545454;
    max-width: 100%;
    margin: 0% !important;
}

.delete-btn {
    font-family: var(--primary-font-medium);
    font-weight: lighter;
    font-size: medium;
    /* height: 60%; */
    width: 15vh;
    border: none;
    padding: 1%;
    margin-left: 3%;
    background: #DC3545;
    border-radius: 10px;
    color: #ffff;
}

.cancel-btn {
    font-family: var(--primary-font-medium);
    font-weight: lighter;
    font-size: medium;
    /* height: 60%; */
    width: 15vh;
    border: 1px solid #DC3545;
    padding: 1%;
    margin-left: 3%;
    background: #FFFFFF;
    border-radius: 10px;
    color: #DC3545;
}

.cancel-btn-2 {
    font-family: var(--primary-font-medium);
    font-weight: lighter;
    font-size: medium;
    /* height: 60%; */
    width: 15vh;
    padding: 1%;
    margin-left: 3%;
    background: var(--primary-color);

    border: none !important;
    border-radius: 10px;
    color: #FFFFFF;
}

.save-btn {
    font-family: var(--primary-font-medium);
    font-weight: lighter;
    font-size: medium;
    /* height: 60%; */
    width: 15vh;
    border: none;
    padding: 1%;
    margin-left: 3%;
    background: #0077b6;
    border-radius: 10px;
    color: #ffff;
}

/* .logo {
        padding: 0% !important;
        margin: 0% !important;
        height: 250px;
    } */

.nc-modal-custom-row-grey {
    font-family: var(--primary-font-bold);
    font-weight: normal;
    font-size: medium;
    color: #545454;
    width: 100%;
    /* margin-bottom: 2%; */
}

.nc-modal-custom-row-view {
    font-family: var(--primary-font-medium);
    font-weight: bold;
    font-size: medium;
    color: #545454;
    width: 100%;
    
}

.nc-modal-custom-input {
    font-family: var(--primary-font-medium);
    font-weight: normal;
    font-size: medium;
    border-radius: 10px !important;
    border: #B9B9B9 1px solid !important;
    color: #5E5E5E !important;
}

.nc-modal-custom-text {
    font-family: var(--primary-font-medium);
    font-weight: normal;
    font-size: medium;
    width: 100%;
}

.nc-modal-custom-dropdown {
    font-family: var(--primary-font-medium);
    font-weight: normal;
    font-size: medium;
    border-radius: 10px !important;
    border: #B9B9B9 1px solid !important;
    width: 100%;
    margin-bottom: 5% !important;
    height: 43% !important;
}

.nc-modal-custom-input-edit {
    font-family: var(--primary-font-medium);
    font-weight: normal;
    font-size: medium;
    border-radius: 10px !important;
    border: #B9B9B9 1px solid !important;
    width: 100%;
    color: #5E5E5E !important;
}


.space {
    color: #5E5E5E !important;
}

.container-wrapper {
    box-sizing: border-box;
    background: #E6E5E5;
    border-radius: 5px;
    width: 100% !important;
    padding: 1% !important;
    margin: 0% !important;
    height: 800px;
    overflow-y: scroll !important;
    
}

.align-contents {
    justify-content: center;
    align-items: center;
}

.form-check-label {
    padding-left: 3;
    font-family: var(--primary-font-medium);
}

.form-check-input:checked {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.btn-primary {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
}

.nc-modal-custom-row-green {
    font-family: var(--primary-font-medium)!important;
    color: var(--primary-color);
}

.nc-modal-custom-row-green-not-bold {
    font-family: var(--primary-font-light)!important;
    color: var(--primary-color);
    letter-spacing: 0.05em;
}

@media (min-width: 320px) and (max-width: 780px) {
    .custom-modal-body-title-user-details {
        padding-left: 5% !important;
    }

    .warning {
        width: 15%;
    }

    .nc-modal-custom-row {
        font-family: var(--primary-font-medium);
        color: var(--primary-color);
        width: 100%;
    }

    .nc-modal-custom-row-grey {
        font-family: var(--primary-font-medium);
        font-weight: normal;
        font-size: medium;
        color: #545454;
        width: 100%;
    }

}

.text-right {
    /* display: inline-block; */
    text-align: right;
    /* margin-right: 1em; */
    /* padding-right: 1em; */
}

.nc-modal-custom-input-number {
    font-family: var(--primary-font-medium);
    font-weight: normal;
    font-size: medium;
    border-radius: 10px !important;
    border: #B9B9B9 1px solid !important;
    width: 100%;
    display: inline-block !important;
    text-align: right;
}

.modal-cont-receivables {
    max-width: 60% !important;
    position: absolute !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
}

  .review-form,
  .edit-form {
    background: #ffffff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 4vh 2vw;
    font-family: var(--primary-font-medium) !important;
    color: var(--primary-color);
    text-transform: uppercase;
  }

  .edit-form .form-control,
  .edit-form .form-select,
  .edit-form .react-select__control {
    border: 1px solid #b9b9b9;
    border-radius: 10px;
    font-family: var(--primary-font-medium) !important;
  }