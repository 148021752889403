body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar-track {
  background-color: var(--secondary-color) !important;
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondary-color) !important;
  border-radius: 4px; 
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--secondary-color) !important;
}


:root {
  /* --primary-color: var(--primary-color);
  --primary-font-light: 'Gotham-Rounded-Light';
  --primary-font-regular: 'Varela-Rounded-Regular';
  --primary-font-medium: 'Gotham-Rounded-Medium';
  --primary-font-bold: 'Gotham-Rounded-Bold'; */

  --primary-color: #8cc43d;
  --secondary-color: #c4e093;
  --primary-font-light: 'Gotham-Rounded-Light';
  --primary-font-regular: 'Varela-Rounded-Regular';
  --primary-font-medium: 'Gotham-Rounded-Medium';
  --primary-font-bold: 'Gotham-Rounded-Bold';
}

/**FONTS**/
@font-face {
  font-family: 'Gotham-Rounded-Medium' ;
  src: url('../src/Assets/Fonts/GothamRoundedRegular/GothamRounded-Medium.otf');
}

@font-face {
  font-family: 'Gotham-Rounded-Bold';
  src: url('../src/Assets/Fonts/GothamBold/GothamRoundedBold_21016.ttf');
}

@font-face {
  font-family: 'Gotham-Rounded-Light';
  src: url('../src/Assets/Fonts/GothamRoundedLight/Gotham-Rounded-Light.otf');
}

@font-face {
  font-family: 'Varela-Rounded-Regular';
  src: url('../src/Assets/Fonts/VerelaRoundRegular/VarelaRound-Regular.ttf');
}

@font-face {
  font-family: 'Concert-One';
  src: url('../src/Assets/Fonts/ConcertOne/ConcertOne-Regular.ttf');
}

