

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.password-wrapper-login {
  width: 93.5% !important;
  margin-left: -5.0%;
}

.bold {
  font-weight: bold !important;
}

  input[type='radio']:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -4px;
        left: -1px;
        position: relative;
        background-color: #d1d3d1;
    }

    input[type='radio']:checked:after {
        background-color: var(--primary-color);
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid white;
    }

.mt-10 {
  margin-top: 10% !important;
}

.loader {
  padding-left: 3.5% !important;
  padding-bottom: 1% !important;
}

.eye-slash {
  display: inline;
  width: 5%;
  margin-left: -1%;
}

.required-icon {
  color: #df1227;
  margin-left: 1%;
}

/** Margin classes**/
.move-l-2 {
  margin-left: 2%;
}

.move-l-3 {
  margin-left: 3%;
}

.move-l-4 {
  margin-left: 4%;
}

.move-l-5 {
  margin-left: 4%;
}

.move-l-8 {
  margin-left: 8%;
}

.move-l-16-5 {
  margin-left: 16.5%;
}

.move-r-2 {
  margin-right: 2%;
}

.move-r-3 {
  margin-right: 3%;
}

.move-r-4 {
  margin-right: 4%;
}

.move-r-5 {
  margin-right: 4%;
}

.move-r-8 {
  margin-right: 8%;
}

.move-r-16-5 {
  margin-right: 16.5%;
}

input:disabled {
  background: #dddddd;
}

select:disabled {
  background: #dddddd;
}

/* dropsearch placed in filter by row css */
.manager-tabs {
  position: relative;
}

.dropsearch-filter {
  padding: 0 0;
  border-radius: 10px !important;
  width: 20%;
  position: absolute;
  z-index: 2;
  margin-right: 20px;
  text-transform: none;
}

.css-1s2u09g-control {
  border-radius: 10px !important;
}
.css-1s2u09g-control:hover {
  border-radius: 10px !important;
}

/* printing configs */
@media print {
  table {
      page-break-inside: avoid;
  }
  @page {
      size: A3;
      margin: 30px;
  }

  .additional-note-input {
      display: none !important;
  }

  .print-logo {
      width: 100px;
  }

  .print-shop-header, .print-label, .print-data, .print-table td, .print-table th {
      font-size: 10px;
  }

  .grand-label {
      margin-right: 10px;
  }

  .print-signatures {
      margin-top: 10px;
  }
  

}

.app-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 1rem;
}

form {
  display: flex;
  gap: 5px;
}

form td:last-child {
  display: flex;
  justify-content: space-evenly;
}

form * {
  font-size: 28px;
}

.badge-required {
  display: inline-block;
  margin-inline-end: 0;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
  margin-left: 0.5em;
  content: "*";
}

.gold-bg {
  background-color: gold !important
}

.ant-select, .ant-select-outlined {
  min-height: 38px !important;
  border-radius: 0.9em !important;
}

.ant-picker {
  min-height: 38px !important;
  border-radius: 0.6em !important;
}

/* change the calendar background to white */
.ant-picker-cell {
  background-color: white !important;
  font-family: var(--primary-font-regular);
}

.ant-picker-content th {
  background-color: rgb(231, 231, 231);
  font-family: var(--primary-font-medium);
}

.rounded-full{
  border-radius: 100%;
}

.card-image{
  width: 100px;
  height: 130px;
  object-fit: cover;
}

.primary-button-style{
  background-color: var(--primary-color);
  border: none;
  color: white;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 90px;
}

.d-hidden{
  display: none;
}

.uppercase{
  text-transform: uppercase;
}

.danger-button-style {
  border: 1px solid red;
  background-color: white;
  color: red;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 90px;
}

.w-sm {
  width: 5rem;
}

.w-mini{
  width: 15rem;
}

.w-lg {
  width: 35rem;
}

.w-md {
  width: 7rem;
}

.w-md-force {
  width: 500px !important;
}

.w-medium {
  width: 17rem;
}

.h-10{
  height: 4.7rem;
}

.general-ledger-button{
  background-color: rgb(155, 155, 155);
  color: white;
  border: none;
  border-radius: 5px;
}

.custom-antd-table{
  .ant-table {
    & .ant-table-thead {
        .ant-table-cell {
            background-color: #3C3C42;
            color: white !important;
            font-family: var(--primary-font-medium);
            padding: 1%;
        }
    }
}
}

.dropdown-select-style{
  width: 100%;
}

@media screen and (min-width: 768px) {
  .dropdown-select-style {
    width: 250px !important;
  }
}

.review-pinv{
  .review-purchased-items td:nth-child(4),
  .review-purchased-items td:nth-child(5),
  .print-table td:nth-child(4),
  .print-table td:nth-child(5),
  .print-table-footer-data {
    color: black;
    font-family: var(--primary-font-medium) !important;
  }
}

.bu-logo{
  height: 136px;
  width: 136px;
  overflow: hidden;
  border-radius: 100%;

  .landing-logo{
    height: 136px;
    width: 136px;
    object-fit: cover;
  }
}