.add-type-btn {
    border: none;
    border-radius: 5px;
    background-color: var(--primary-color);
    color: #FFFFFF; 
}

.TabStyle1 {
    display: flex !important;
    flex-wrap: nowrap !important;
    align-items: stretch;
    margin: 0;
    padding: 0;
    overflow-y: hidden;
}
  
.TabStyle2 {
    flex: 1;
    text-align: center;
}

.TabStyle4 {
  display: flex !important;
  flex-wrap: nowrap !important;
  align-items: stretch;

  .nav-link,
  .nav-tabs .nav-link:hover {
    width: 100% !important;
  }

  .nav-link.active,
  .nav-link.active:hover,
  .react-select__option--is-selected {
    width: 100% !important;
  }
}

.margin-top-5 {
  margin-top: 5vh !important;
}

.stock-in{
  .purchased-items-table-container {
      border: 1px solid rgb(221, 217, 217);
      overflow-x: visible !important;
      padding: 1% 0;
  }
}



.purchased-items-table-container {
  border: 1px solid rgb(221, 217, 217);
  overflow-x: scroll !important;
  padding: 1% 0;
  

  .project-pi-table {
    width: 120vw !important;

    .freeze1 {
      position: sticky;
      left: 0;
      z-index: 200;
    }
    .freeze2 {
      position: sticky;
      left: 124px;
      z-index: 200;
    }
    .freeze3 {
      position: sticky;
      left: 248px;
      z-index: 200;
    }
    .freeze4 {
      position: sticky;
      left: 370px;
      z-index: 200;
    }

    .form-control{
      width: 120px !important;
    }
  }
}

.purchased-items-table thead th {
  font-family: var(--primary-font-medium);
  font-size: 9pt !important;
  text-transform: uppercase;
}

.purchased-items-table tbody td {
  font-family: var(--primary-font-medium);
  font-size: 9pt !important;
  vertical-align: middle;
  align-items: center;
  padding: 8px 2px;

  .form-control, 
  .ant-select-selection-search-input, 
  .ant-select-selection-item,
  .ant-select-selection-placeholder, .ant-select {
    font-family: var(--primary-font-medium);
    font-size: 9pt !important;
    text-align: left !important;
    width: 100% !important;

  }
}

.smaller-text {
  font-size: 8pt;
}

.pointer {
  cursor: pointer !important;
}